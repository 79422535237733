<template>
  <div class="loading-wrap">
    <van-image width="64" height="64" src="./imgs/loading.svg" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['jwt', 'isExpired', 'user', 'userType'])
  },
  created() {
    console.log(this.jwt, this.isExpired)
    if (this.jwt && !this.isExpired) { // 有效登陆
      if (this.userType === 'CUST') {
        this.$router.replace('/cust-my/my')
      }
      if (this.userType === 'STAFF') {
        this.$router.replace('/my/mng')
      }
    } else { // 未登陆或过期
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped>

</style>
